import { AJAX, timeoutPromise } from "./helpers.js";
import config from "./config.js";

const passElement = document.querySelector("#mob-password");
const errorElement = document.querySelector("#mob-err-label");
const cookieAllowButt = document.querySelector("#mob-allow");
const cookieNoAllowButt = document.querySelector("#mob-noallow");
const cookieBanner = document.querySelector("#mob-cookie-banner");
const cookieSettings = document.querySelector("#mob-cookie-settings");
const helpButt = document.querySelector(".mob-help-butt");
const helpEl = document.querySelector(".mob-errlabel");
const helpText =
  "Your access code is a ten digit number that you have been provided with your badge. If you did not receive this code, or the code you have doesn't work, please contact the players desk directly under:  <a href='tel:+43 664 963 1929' style='color: var(--color-exotic)'>+43 664 963 1929</a>";
const loginButt = document.querySelector(".mob-login-butt");
const loginSVG = document.querySelector(".loginButtSVG");
const helpSVG = document.querySelector(".helpButtSVG");

function setCookie(days0, cookieName0, cookieValue0) {
  var d = new Date();
  d.setTime(d.getTime() + days0 * 24 * 60 * 60 * 1000); // expires in days0 days
  var expires = "expires=" + d.toUTCString();

  document.cookie =
    cookieName0 +
    "=" +
    cookieValue0 +
    ";" +
    expires +
    ";domain=" +
    config.API_DOMAIN +
    ";path=/;";
}

function getCookie(name0) {
  const cookies = document.cookie.split(";");

  if (cookies[0].length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      const decodedCookieName = decodeURIComponent(cookieName.trim());
      const decodedCookieValue = decodeURIComponent(cookieValue.trim());

      if (decodedCookieName === name0) {
        return decodedCookieValue;
      }
    }
  }
  return null;
}

function showCookiesBanner() {
  setCookie(30, "mobCookiesAllow", 0);
  cookieBanner.style.animation = "none";
  allowCookiesOrNot();
}
function showHelpText() {
  if (
    helpEl.innerHTML.localeCompare(helpText) &&
    helpEl.style.visibility === "visible"
  ) {
    helpEl.innerHTML = "";
    helpEl.style.visibility = "hidden";
    helpSVG.style.fill = "white";
    helpSVG.style.stroke = "white";
  } else {
    helpEl.innerHTML = helpText;
    helpEl.style.visibility = "visible";
    helpSVG.style.fill = "var(--color-exotic)";
    helpSVG.style.stroke = "var(--color-exotic)";
  }
}

const allowCookies = async function () {
  try {
    const persistedData = await AJAX(`${config.API_URL}mobcookies/`, null, {
      answer: 1,
    });

    const cookieAllowed = getCookie("mobCookiesAllow");

    if (cookieAllowed > 0) {
      setCookie(30, "mobCookiesAllow", 1);
      cookieBanner.style.display = "none";
      cookieSettings.style.display = "flex";
    }
  } catch (err) {
    alert("DENNIS error:" + err.msg);
  }
};
const allowCookiesOrNot = async function () {
  try {
    const cookieCookie = getCookie("mobCookiesAllow");

    if (cookieCookie) {
      if (Number(cookieCookie) === 0) {
        cookieSettings.style.display = "none";
        cookieBanner.style.display = "flex";
      } else {
        if (Number(cookieCookie) === 1) {
          cookieBanner.style.display = "none";
          cookieSettings.style.display = "flex";
        } else {
          cookieSettings.style.display = "none";
          cookieBanner.style.display = "flex";
        }
      }
    } else {
      cookieSettings.style.display = "none";
      cookieBanner.style.display = "flex";
    }
  } catch (err) {
    throw e;
  }
};
const allowNotCookies = async function () {
  try {
    const persistedData = await AJAX(`${config.API_URL}mobcookies/`, null, {
      answer: -1,
    });

    const cookieAllowed = getCookie("mobCookiesAllow");
    if (cookieAllowed < 0) {
      setCookie(30, "mobCookiesAllow", -1);
      cookieBanner.style.display = "none";
      cookieSettings.style.display = "flex";
    }
  } catch (err) {
    alert("DENNIS error:" + err.msg);
    throw err;
  }
};
const login = async function (loginData0) {
  try {
    const persistedData = await AJAX(
      `${config.API_URL}moblogin/`,
      null,
      loginData0
    );

    return persistedData;
  } catch (err) {
    throw err;
  }
};
const loginDataPrepare = async function () {
  helpEl.style.visibility = "hidden";
  const passwordValue = passElement.value.trim();
  if (passwordValue.length > 0) {
    const loginObj = { passwordValue };

    try {
      const cookiesAllowed = getCookie("mobCookiesAllow");
      if (cookiesAllowed < 1) {
        helpEl.innerText = "You need to allow cookies to log in";
        helpEl.style.visibility = "visible";
        loginSVG.style.stroke = "white";
        return;
      }
      let loginData = await login(loginObj);
      if (!loginData || loginData.status == "NOT OK") {
        helpEl.innerText = loginData.msg;
        helpEl.style.visibility = "visible";
        loginSVG.style.stroke = "white";
      } else {
        helpEl.innerText = loginData.msg;
        helpEl.style.visibility = "visible";
        location.assign(config.SUB_URL);
      }
    } catch (err) {
      helpEl.innerText = err.msg;
      helpEl.style.visibility = "visible";
    }
  } else {
    helpEl.innerText = "You need to provide the access code";
    helpEl.style.visibility = "visible";
    loginSVG.style.stroke = "white";
  }
};
const mobLogin = async function () {
  loginSVG.style.stroke = "var(--color-exotic)";
  await loginDataPrepare();
  // let ck = getCookie("dennisMobToken");
  // setCookie(7, "dennisMobToken", ck);
};

cookieAllowButt.addEventListener("click", allowCookies);
cookieNoAllowButt.addEventListener("click", allowNotCookies);
cookieSettings.addEventListener("click", showCookiesBanner);
helpButt.addEventListener("click", showHelpText);
loginButt.addEventListener("click", mobLogin);

allowCookiesOrNot();
